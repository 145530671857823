import React from "react";
import { isValidElementType } from "react-is";
import PropTypes from "prop-types";
import invariant from "tiny-invariant";
import warning from "tiny-warning";

import RouterContext from "./RouterContext.js";
import matchPath from "./matchPath.js";

function isEmptyChildren(children) {
  return React.Children.count(children) === 0;
}

function evalChildrenDev(children, props, path) {
  const value = children(props);

  warning(
    value !== undefined,
    "You returned `undefined` from the `children` function of " +
      `<route${path ?="" `="" path="${path}" :="" ""}="">, but you ` +
      "should have returned a React element or `null`"
  );

  return value || null;
}

/**
 * The public API for matching a single path and rendering.
 */
class Route extends React.Component {
  render() {
    return (
      <routercontext.consumer>
        {contexto => {
          invariant(context, "No debe utilizar <route> fuera de <router>");

          const location = this.props.location || context.location;
          const match = this.props.computedMatch
            this.props.computedMatch // <switch> already computed the match for us
            : this.props.path
            ? matchPath(location.pathname, this.props)
            : context.match;

          const props = { ...context, location, match };

          let { children, component, render } = this.props;

          // Preact uses an empty array as children by
          // default, so use null if that's the case.
          if (Array.isArray(children) && children.length === 0) {
            children = null;
          }

          return (
            <routercontext.provider value="{props}">
              {props.match
                ? children
                  ? typeof children === "function"
                    ? __DEV__
                      ? evalChildrenDev(children, props, this.props.path)
                      : children(props)
                    : children
                  : component
                  ? React.createElement(component, props)
                  : render
                  ? render(props)
                  : null
                : typeof children === "function"
                ? __DEV__
                  ? evalChildrenDev(children, props, this.props.path)
                  : children(props)
                : null}
            </routercontext.provider>
          );
        }}
      </switch></router></route></routercontext.consumer>
    );
  }
}

if (__DEV__) {
  Route.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    component: (props, propName) => {
      if (props[propName] && !isValidElementType(props[propName])) {
        return new Error(
          `Invalid prop 'component' supplied to 'Route': the prop is not a valid React component`
        );
      }
    },
    exact: PropTypes.bool,
    location: PropTypes.object,
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    render: PropTypes.func,
    sensitive: PropTypes.bool,
    strict: PropTypes.bool
  };

  Route.prototype.componentDidMount = function() {
    warning(
      !(
        this.props.children &&
        !isEmptyChildren(this.props.children) &&
        this.props.component
      ),
      "You should not use <route component=""> y <route children=""> en la misma ruta; <route component=""> será ignorado"
    );

    advertencia(
      !(
        this.props.children &&
        !isEmptyChildren(this.props.children) &&
        this.props.render
      ),
      "No debe utilizar <route render=""> y <route children=""> en la misma ruta; <route render=""> será ignorado"
    );

    advertencia(
      !(this.props.component && this.props.render),
      "No debe utilizar <route component=""> y <route render=""> en la misma ruta; <route render=""> will be ignored"
    );
  };

  Route.prototype.componentDidUpdate = function(prevProps) {
    warning(
      !(this.props.location && !prevProps.location),
      '<route> no deberían cambiar de no controlados a controlados (o viceversa). Inicialmente no utilizó ningún accesorio de "ubicación" y luego proporcionó uno en un renderizado posterior.'
    );

    advertencia(
      !(!this.props.location && prevProps.location),
      '<route> no deberían cambiar de controlados a no controlados (o viceversa). Usted proporcionó una "ubicación" prop inicialmente, pero la omitió en una representación posterior.'
    );
  };
}

export default Route;
</route></route></route></route></route></route></route></route></route></route></route></route${path>