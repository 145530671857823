import React de "react";
import PropTypes de "prop-types";
import invariant de "tiny-invariant";
import warning de "tiny-warning";

import RouterContext de "./RouterContext.js";
import matchPath de "./matchPath.js";

/**
 * La API pública para mostrar el primer <route> que coincide.
 */
class Switch extends React.Component {
  render() {
    return (
      <routercontext.consumer>
        {contexto => {
          invariant(context, "No debe utilizar <switch> fuera de <router>");

          const location = this.props.location || context.location;

          let elemento, coincidencia;

          // Usamos React.Children.forEach en lugar de React.Children.toArray().find()
          // aquí porque toArray añade claves a todos los elementos hijos y no queremos
          // desencadenar un desmontaje/remontaje por dos <route>s that render the same
          // component at different URLs.
          React.Children.forEach(this.props.children, child => {
            if (match == null && React.isValidElement(child)) {
              element = child;

              const path = child.props.path || child.props.from;

              match = path
                ? matchPath(location.pathname, { ...child.props, path })
                : context.match;
            }
          });

          return match
            ? React.cloneElement(element, { location, computedMatch: match })
            : null;
        }}
      </route></router></switch></routercontext.consumer>
    );
  }
}

if (__DEV__) {
  Switch.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object
  };

  Switch.prototype.componentDidUpdate = function(prevProps) {
    warning(
      !(this.props.location && !prevProps.location),
      '<switch> no deberían cambiar de no controlados a controlados (o viceversa). Inicialmente no utilizó ningún accesorio de "ubicación" y luego proporcionó uno en un renderizado posterior.'
    );

    advertencia(
      !(!this.props.location && prevProps.location),
      '<switch> no deberían cambiar de controlados a no controlados (o viceversa). Usted proporcionó una "ubicación" prop inicialmente, pero la omitió en una representación posterior.'
    );
  };
}

export default Switch;
</switch></switch></route>